import React from 'react';
import { Link, graphql } from 'gatsby';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

const NotFound404Page = (props) => {
  const {
    data: { bgContact },
  } = props;

  return (
    <BodyClassName className="body-light page-404">
      <DefaultLayoutComponent title="Page Not Found">
        <div className="site-main">
          <BackgroundImage
            className="page-banner bgImage"
            fluid={bgContact.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-md pb-container mg-b-0">
              <h2 className="pb-title">
                <span>4</span>
                <span>0</span>
                <span>4</span>
              </h2>
              <h4>Page Not Found</h4>
              <Link to="/" className="btn btn-sm btn-primary-ii pb-link">
                Back
              </Link>
            </div>
          </BackgroundImage>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgContact: file(relativePath: { eq: "bg-contact.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NotFound404Page;
